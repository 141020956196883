import trackMixpanelEvent from "SKNUI/mixpanel/mixpanel";
import { pickUserFromDjango } from "SKNUI/scripts";

const { user } = pickUserFromDjango();

if (!user?.trackingOff) {
  trackMixpanelEvent("View Landing", {
    Page: "Homepage",
    "Logged In": user?.authenticated,
    "Is School": user?.isSchool,
    School: user?.school ? user?.school.name : undefined,
    Campaign: new URLSearchParams(document.location.search).get("cmp"),
  });
}
